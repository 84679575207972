@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.c-topicspath {
  max-width: 1138px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  left: 0;
  bottom: 60px;
  z-index: 2; }
  @media screen and (max-width: 1168px) {
    .c-topicspath {
      padding: 0 15px; } }
  @media screen and (max-width: 768px) {
    .c-topicspath {
      bottom: 40px; } }
  @media screen and (max-width: 568px) {
    .c-topicspath {
      bottom: 30px; } }
  .c-topicspath li {
    display: inline-block;
    font-size: 12px;
    color: #888888; }
    @media screen and (max-width: 768px) {
      .c-topicspath li {
        font-size: 0.7em; } }
    .c-topicspath li:after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: inline-block;
      margin-left: 8px; }
    .c-topicspath li:last-child:after {
      content: none; }
    .c-topicspath li > a {
      color: #888888; }

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 800px;
  background-color: #FAFAFA;
  overflow: hidden; }
  @media screen and (max-width: 1280px) {
    .l-lowerCaption {
      height: 500px; } }
  @media screen and (max-width: 768px) {
    .l-lowerCaption {
      height: 400px; } }
  @media screen and (max-width: 568px) {
    .l-lowerCaption {
      height: 350px; } }
  .l-lowerCaption__inner {
    max-width: 1138px;
    width: 100%;
    height: 334px;
    margin: 0 auto;
    position: relative; }
    @media screen and (max-width: 1280px) {
      .l-lowerCaption__inner {
        padding: 0 15px;
        height: 250px; } }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__inner {
        height: 200px; } }
    @media screen and (max-width: 568px) {
      .l-lowerCaption__inner {
        height: 190px; } }
  .l-lowerCaption__title {
    font-size: 38px;
    font-weight: bold;
    color: #000;
    padding: 0;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 2; }
    @media screen and (max-width: 1168px) {
      .l-lowerCaption__title {
        padding: 0 15px; } }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__title {
        font-size: 32px; } }
    @media screen and (max-width: 568px) {
      .l-lowerCaption__title {
        font-size: 28px; } }
    .l-lowerCaption__title small {
      font-size: 18px;
      font-weight: 600;
      color: #000;
      display: block;
      letter-spacing: 0.06em;
      text-transform: uppercase; }
    .l-lowerCaption__title--sub {
      color: #F0F0F0;
      opacity: .5;
      font-size: 180px;
      font-weight: 900;
      position: absolute;
      right: 0;
      top: 40%;
      transform: translateY(-50%); }
      @media screen and (max-width: 1280px) {
        .l-lowerCaption__title--sub {
          top: 50%;
          font-size: 140px; } }
      @media screen and (max-width: 1024px) {
        .l-lowerCaption__title--sub {
          font-size: 100px; } }
      @media screen and (max-width: 768px) {
        .l-lowerCaption__title--sub {
          font-size: 72px; } }
